<template>
  <header class="z-50 w-full px-1 py-3 lg:px-2 lg:py-5">
    <div class="flex w-full rounded-lg text-gray-800">
      <div class="mr-auto flex flex-1 items-center">
        <NuxtLink to="/" class="block w-24 text-nasared-500 lg:w-36">
          <BrandLogo class="full block" />
        </NuxtLink>
      </div>
      <nav class="flex-0 hidden items-center lg:flex">
        <ul>
          <li
            v-for="(item, index) in mainMenu"
            :key="item.label"
            class="relative mr-7 inline-block last:mr-0"
          >
            <UDropdown
              v-if="item.children"
              :items="item.children"
              :popper="{ placement: 'bottom' }"
            >
              <UButton
                color="white"
                size="md"
                block
                variant="link"
                trailing-icon="i-heroicons-chevron-down-20-solid"
                :ui="{
                  base: 'p-0 text-black border-b-2 border-t-2 border-t-transparent border-b-transparent transition-colors hover:border-b-nasared-500 [&.router-link-active]:border-b-gray-500',
                  font: 'font-semibold',
                  rounded: 'rounded-none',
                  size: { md: 'text-md' },
                  padding: {
                    md: 'px-0 py-1',
                  },
                  variant: {
                    link: 'hover:no-underline',
                  },
                }"
              >
                {{ item.label }}
              </UButton>
              <template #item="{ item }">
                {{ item.label }}
              </template>
            </UDropdown>

            <nuxt-link
              v-else
              :to="item.to"
              class="text-md block select-none border-y-2 border-y-transparent py-1 font-semibold transition-colors hover:border-b-nasared-500 [&.router-link-active]:border-b-gray-500"
              >{{ item.label }}</nuxt-link
            >
          </li>
        </ul>
      </nav>

      <div class="ml-auto flex flex-1 justify-end">
        <ul class="flex items-center space-x-1">
          <li>
            <UButton
              icon="i-fa6-solid-magnifying-glass"
              size="lg"
              :variant="route.name === 'search' ? 'solid' : 'ghost'"
              color="primary"
              variant="ghost"
              square
              @click="openSearchModal"
            />
          </li>

          <li>
            <UButton
              icon="i-fa6-solid-user"
              active-class="current"
              :to="shopifyAccountUrl"
              size="lg"
              color="primary"
              variant="ghost"
              square
              class="font-bold"
            />
          </li>

          <li>
            <UChip :text="quantityDisplay" size="2xl" :show="cartQuantity > 0">
              <UButton
                icon="i-fa6-solid-cart-shopping"
                active-class="current"
                to="/cart"
                size="lg"
                color="primary"
                :variant="
                  route.name === 'cart'
                    ? 'solid'
                    : cartQuantity > 0
                      ? 'outline'
                      : 'ghost'
                "
                square
                class="font-bold"
              />
            </UChip>
          </li>

          <li class="lg:hidden">
            <UButton
              icon="i-fa6-solid-bars"
              size="lg"
              color="primary"
              variant="ghost"
              square
              @click="isMobileMenuOpen = true"
            />
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
const store = useStore();
const route = useRoute();
const {
  shop,
  mainMenu,
  isMobileMenuOpen,
  isSearchModalOpen,
  cartQuantity,
  isLoggedIn,
} = storeToRefs(store);

const { shopifyAccountUrl } = useRuntimeConfig().public;

const openSearchModal = () => {
  if (route.name !== "search") isSearchModalOpen.value = true;
};

const quantityDisplay = computed(() => {
  if (cartQuantity.value >= 100) {
    return "99+";
  } else {
    return cartQuantity.value.toString();
  }
});
</script>

<style scoped>
.reveal-enter-active,
.reveal-leave-active {
  width: 100%;
  opacity: 1;
  transition: width 0.8s linear;
}

.reveal-enter-from,
.reveal-leave-to {
  width: 0;
  opacity: 0;
}
</style>
